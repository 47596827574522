/* Libraries */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { gsap } from 'gsap';

/* Styles */
import './attemptmessage.scss';

/* Components */
import shake from './shake.mp3';
import { playAudio } from '../../helpers/audioPlay';

const messages = [
  'Det var fel. Jingle better!!',
  'Det strålar ingen stjärna direkt - fel!',
  'Inget svar att hänga i granen - fel!',
  'Let it snowa lite bättre svar tack!',
  'Behöver vi ett Xmas miracle?!',
  'Har du tip tappat det helt?',
  'Rymde julskinkan med din hjärna?',
];

function getRandomMessage() {
  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
}

const AttemptMessage = ({
  feedbackMessage = '',
  triggerAnimation = false,
  setTriggerAnimation = () => {},
  errorCount = 0,
}) => {
  const elementRef = useRef(null);
  const [randomMessage, setRandomMessage] = useState(getRandomMessage());

  useEffect(() => {
    if (triggerAnimation) {
      setRandomMessage(getRandomMessage());
      playAudio({ sound: shake });

      const element = elementRef.current;
      const tl = gsap.timeline({
        onComplete: () => setTriggerAnimation(false), // Set triggerAnimation to false after animation completes
      });

      tl.fromTo(
        elementRef.current,
        { opacity: 1 },
        { opacity: 1, duration: 0.5 },
      ).to(elementRef.current, {
        opacity: 0,
        duration: 1,
        delay: 2.5,
        onComplete: () => tl.kill(),
      });

      gsap.to(elementRef.current, {
        x: '+=10',
        duration: 0.1,
        yoyo: true,
        repeat: 6,
        onComplete: () => gsap.to(elementRef.current, { x: 0 }),
      });

      return () => {
        tl.kill();
        gsap.killTweensOf(element);
      };
    }
  }, [triggerAnimation, setTriggerAnimation]);

  const handleClose = () => {
    gsap.to(elementRef.current, { opacity: 0, duration: 1 });
  };

  const getImageSrc = () => {
    if (errorCount === 2) {
      return `${process.env.PUBLIC_URL}/wrong-two.png`;
    }
    return `${process.env.PUBLIC_URL}/wrong-one.png`;
  };

  return (
    <div ref={elementRef} className={clsx('attempt-message')}>
      <div className={clsx('attempt-content')}>
        <button className="close-button" onClick={handleClose}></button>
        <img
          alt="Sad ginger bread"
          src={getImageSrc()}
          className="attempts-image"
        />
        <div className="attempts-text">
          <p>{randomMessage}</p>{' '}
          <p>
            <span className="attempts-number">{feedbackMessage}</span> försök
            kvar!
          </p>
        </div>
      </div>
    </div>
  );
};
export default AttemptMessage;
