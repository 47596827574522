/* Libraries */
import React from 'react';
import clsx from 'clsx';

/* Styles */
import './button.scss';

/* Components */
import useHoverSound from '../../helpers/useHoverSound';

// Ex. på knappar:
// <Button title={'Hjälp'}></button
// <Button title={'Hjälp'} size={'small'} color={'maroon'} icon={'share'}></Button>
// <Button title={'Hjälp'} href={'/help'}></Button>
const Button = ({ title, color, size, icon, onClick = () => {}, href, id }) => {
  const className = clsx('button', color, size, icon);
  const playHoverSound = useHoverSound();

  if (href) {
    return (
      <a
        className={className}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={playHoverSound}
      >
        {title}
      </a>
    );
  }

  return (
    <button
      className={className}
      onClick={onClick}
      onMouseEnter={playHoverSound}
      id={id}
    >
      {title}
    </button>
  );
};

export default Button;
