export default class CookiebotBuilder {
  constructor() {
    this.body = document.querySelector('html');

    const callback = (mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          if (mutation.addedNodes.length > 0) {
            for (let node of mutation.addedNodes) {
              if (node.nodeType === 1) {
                if (node.id === 'cookiebanner') {
                  fetchElements();
                }
              }
            }
          }
        }
      }
    };
    const observer = new MutationObserver(callback);

    observer.observe(this.body, {
      subtree: true,
      childList: true,
    });

    const fetchElements = () => {
      this.cookieBanner = document.getElementById('cookiebanner');
      if (this.cookieBanner) {
        this.listElements = document.querySelectorAll(
          '#cookiebanner .c-header li',
        );
        this.informationLink = document.getElementById('information');
        this.aboutLink = document.getElementById('about');
        this.consentLink = document.getElementById('consent');
        this.information = document.querySelector('.information-wrapper');
        this.about = document.querySelector('.about-wrapper');
        this.consent = document.querySelector('.switch-container-wrapper');
        this.menuText = document.querySelector('.c-message');
        this.foldOutContainers = document.querySelectorAll(
          '.fold-out-container-inner',
        );
        this.checkboxes = document.querySelectorAll('.switch-wrapper input');
        this.buttons = document.querySelectorAll('.c-button');
        this.foldOutButtonSpans = document.querySelectorAll(
          '.fold-out-button span',
        );
        this.tables = this.cookieBanner.querySelectorAll('table');
        this.theads = this.cookieBanner.querySelectorAll('thead');
        this.menuElements = [
          this.about,
          this.information,
          this.consent,
          this.menuText,
        ];
        this.init();
      }
    };
  }

  init() {
    if (this.cookieBanner) {
      this.handleScreenSize();
      this.addEventListeners();
    }
  }

  removeStyleFromSpanChild(span) {
    const foldOutContainerInner = span.closest('.fold-out-container-inner');
    const tr = foldOutContainerInner.querySelector('table tbody tr');
    tr.classList.add('no-cookies');
  }

  removeTextFromSpan(span) {
    const textContentSplit = span.textContent.split(' ');

    if (textContentSplit.length >= 2) {
      span.textContent = textContentSplit[1].replace('(', '').replace(')', '');
      if (span.textContent === '0') {
        this.removeStyleFromSpanChild(span);
      }
    }
  }

  toggleMenuElements(showElement) {
    this.menuElements.forEach((menuElement) => {
      menuElement.classList.add('hidden');
    });

    showElement.classList.remove('hidden');

    if (showElement === this.consent) {
      this.menuText.classList.remove('hidden');
    }
  }

  handleScreenSize() {
    if (this.cookieBanner) {
      if (window.innerWidth < 1440) {
        this.cookieBanner.classList.add('show-modal');
      }
      if (window.innerWidth < 645) {
        this.cookieBanner.classList.remove('show-modal');
      }
    }
  }

  thContent(table) {
    return Array.from(table.querySelectorAll('thead th')).map(
      (th) => th.textContent,
    );
  }

  concatenateThs(table) {
    const ths = Array.from(table.querySelectorAll('thead th')).map(
      (th) => th.textContent,
    );

    const thElements = table.querySelectorAll('thead th');

    if (ths.length > 1) {
      ths[0] = `${ths[0]} - ${ths[1]}`;
      ths.splice(1, 1);
    }

    thElements.forEach((th, index) => {
      if (ths[index]) {
        th.textContent = ths[index];
      } else {
        th.remove();
      }
    });
  }

  toggleDisplay(tr) {
    const div = tr.querySelector('.td-container');
    if (div) {
      const currentDisplay = window.getComputedStyle(div).display;
      if (currentDisplay === 'none') {
        div.style.display = 'flex';
        tr.parentNode.classList.add('open');
        tr.classList.add('open-tr');
      } else {
        div.style.display = 'none';
        tr.parentNode.classList.remove('open');
        tr.classList.remove('open-tr');
      }
    }
  }

  addEvents(tr) {
    tr.addEventListener('click', (event) => {
      event.stopPropagation();
      this.toggleDisplay(tr);
    });

    tr.addEventListener('keydown', (event) => {
      if (document.activeElement === tr && event.key === ' ') {
        this.toggleDisplay(tr);
      }
    });
  }

  processTds(tr, tds, ths) {
    if (tds.length > 1) {
      const [firstTd, secondTd] = tds;
      const link = secondTd.querySelector('a');
      if (link) {
        const newTd = document.createElement('td');
        newTd.appendChild(link.cloneNode(true));
        newTd.innerHTML += ` - ${firstTd.textContent}`;
        tr.insertBefore(newTd, secondTd);
        tr.removeChild(secondTd);
      } else {
        secondTd.textContent += ` - ${firstTd.textContent}`;
      }
      tr.removeChild(firstTd);
    }

    tds = Array.from(tr.children);

    tds.forEach((td, index) => {
      if (index !== 0) {
        td.textContent = `${ths[index + 1]}: ${td.textContent}`;
      }
    });

    return tds;
  }

  createTdContainer(tds) {
    if (tds.length < 2) {
      return;
    }
    const div = document.createElement('div');
    div.className = 'td-container';
    tds
      .filter((td, index) => index !== 0)
      .forEach((td) => {
        if (td instanceof Node) {
          div.appendChild(td);
        } else {
          return;
        }
      });
    return div;
  }

  addEventListeners() {
    this.foldOutButtonSpans.forEach((span) => {
      this.removeTextFromSpan(span);
    });

    this.aboutLink.addEventListener('click', (e) => {
      e.preventDefault();
      this.toggleMenuElements(this.about);
    });

    this.informationLink.addEventListener('click', (e) => {
      e.preventDefault();
      this.toggleMenuElements(this.information);
    });

    this.consentLink.addEventListener('click', (e) => {
      e.preventDefault();
      this.toggleMenuElements(this.consent);
    });

    this.listElements.forEach((list) => {
      list.addEventListener('click', (e) => {
        this.listElements.forEach((otherList) =>
          otherList.classList.remove('underline'),
        );
        e.target.parentNode.classList.add('underline');

        if (window.innerWidth > 1216) {
          this.cookieBanner.classList.add('show-modal');
        }

        this.handleScreenSize();
      });
    });

    this.tables.forEach((table) => {
      const ths = this.thContent(table);
      const trs = table.querySelectorAll('tbody tr');

      trs.forEach((tr) => {
        this.addEvents(tr);
        tr.setAttribute('tabindex', 0);

        let tds = Array.from(tr.children);
        this.processTds(tr, tds, ths);

        tds = Array.from(tr.children); // Update the tds array

        const div = this.createTdContainer(tds);
        if (div) {
          tr.appendChild(div);
        }
      });
    });

    this.tables.forEach((table) => this.concatenateThs(table));

    this.theads.forEach((thead) => {
      thead.classList.add('sr-only');
    });

    this.foldOutContainers.forEach((container) => {
      container.addEventListener('click', () => {
        container.classList.toggle('open-header');
      });
    });

    this.checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('click', (event) => {
        event.stopPropagation();
      });
    });

    window.addEventListener('resize', this.handleScreenSize.bind(this));

    window.dispatchEvent(new Event('resize'));
  }
}
