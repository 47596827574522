/* Libraries */
import React, { useState, useEffect, useCallback } from 'react';

/* Styles */
import './App.scss';

/* Components */
import MusicGame from './Components/musicGame/MusicGame';
import Calendar from './Components/calendar/Calendar';
import ThreeBlocks from './Components/threeBlocks/ThreeBlocks';
import DateContainer from './Components/dateContainer/DateContainer';
import StartPopup from './Components/startPopup/StartPopup';
import CookiebotBuilder from './CookiebotBuilder';

/* Helpers */
import { fetchSingleHatch, fetchAllHatches } from './fetchData';
import { endpoints } from './api/urls';

function App() {
  new CookiebotBuilder();
  const [allHatches, setAllHatches] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [latestHatchNumber, setLatestHatchNumber] = useState(null);
  const [spotifyLink, setSpotifyLink] = useState('');
  const [isFailure, setIsFailure] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [activeDate, setActiveDate] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [rightAnswer, setRightAnswer] = useState('');

  // Funktion för att toggla `isActive`
  const togglePopup = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.body.classList.add('popup-active');
    setIsActive((prevState) => !prevState);
    const closeButton = document.querySelector('.close');
    if (closeButton) {
      closeButton.classList.add('show');
    }
  };

  // Memoize the setIsFailure and setIsSuccess functions
  const memoizedSetIsFailure = useCallback((value) => {
    setIsFailure(value);
  }, []);

  const memoizedSetIsSuccess = useCallback((value) => {
    setIsSuccess(value);
  }, []);

  useEffect(() => {
    const getAllHatches = async () => {
      const url = endpoints.getHatches;
      const result = await fetchAllHatches(url);

      if (result?.success) {
        setAllHatches(result.data);
        const latestNumber = Math.max(
          ...result.data.map((item) => item.hatch_number),
        );
        setLatestHatchNumber(latestNumber);
        setActiveDate(latestNumber);
      }
    };

    getAllHatches();
  }, []);

  useEffect(() => {
    if (latestHatchNumber) {
      const getQuestionData = async () => {
        const url = endpoints.getHatch(latestHatchNumber);
        const result = await fetchSingleHatch(url);

        if (result?.success) {
          setQuizData(result.data);
        }
      };

      getQuestionData();
    }
  }, [latestHatchNumber]);

  return (
    <div className="christmas-card-container">
      <div className="christmas-card-container-left">
        <MusicGame
          hatchNumber={latestHatchNumber}
          isFailure={isFailure}
          setIsFailure={memoizedSetIsFailure}
          isSuccess={isSuccess}
          setIsSuccess={memoizedSetIsSuccess}
          quizData={quizData}
          spotifyLink={spotifyLink}
          setSpotifyLink={setSpotifyLink}
          rightAnswer={rightAnswer}
          setRightAnswer={setRightAnswer}
        />
        <DateContainer
          className={'dates-container-mobile'}
          activeDate={activeDate}
          setActiveDate={setActiveDate}
          togglePopup={togglePopup}
          allHatches={allHatches}
          setLatestHatchNumber={setLatestHatchNumber}
        />
      </div>
      <div className="christmas-card-container-right">
        <ThreeBlocks
          activeDate={activeDate}
          isFailure={isFailure}
          isSuccess={isSuccess}
          today={latestHatchNumber}
          isPopupActive={!isActive}
        />
        <Calendar
          activeDate={activeDate}
          setActiveDate={setActiveDate}
          togglePopup={togglePopup}
          allHatches={allHatches}
          setLatestHatchNumber={setLatestHatchNumber}
        />
      </div>
      <StartPopup isActive={!isActive} setIsActive={setIsActive} />
    </div>
  );
}

export default App;
