import React, { useEffect, useRef } from 'react';

const CookieDeclaration = () => {
  const scriptContainer = useRef(null);

  useEffect(() => {
    if (scriptContainer.current) {
      // Create a script element
      const script = document.createElement('script');
      script.id = 'CookieDeclaration';
      script.src =
        'https://consent.cookiebot.com/a639a53e-af56-4622-bcaf-844036183c96/cd.js';
      script.type = 'text/javascript';
      script.async = true;

      // Append the script to the ref container
      scriptContainer.current.appendChild(script);
    }
  }, []);

  return <div ref={scriptContainer}></div>;
};

export default CookieDeclaration;
