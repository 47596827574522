/* Libraries */
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';

/* Styles */
import './failuremessage.scss';

/* Components */
import ResultMessageLayout from '../resultMessageLayout/ResultMessageLayout';
import failureSound from './lose-sound.mp3';

const FailureMessage = ({ title, text, spotifyLink, rightAnswer }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio(failureSound);
    audioRef.current.play().catch((err) => {
      console.error('Ljudet kunde inte spelas upp:', err);
    });

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current = null;
      }
    };
  }, []);

  return (
    <ResultMessageLayout
      animationText="Oh nooo ho ho!!"
      title={title}
      text={text}
      btnColor="lightred"
      isFailure={true}
      spotifyLink={spotifyLink}
      rightAnswer={rightAnswer}
    />
  );
};
export default FailureMessage;
