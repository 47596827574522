import { useState, useEffect } from 'react';

import hoverSound from '../assets/sounds/hover.mp3';

const useHoverSound = () => {
  const [audio, setAudio] = useState(null);
  const [isUserInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (!isUserInteracted) {
        setUserInteracted(true);
        setAudio(new Audio(hoverSound));
      }
    };

    document.addEventListener('click', handleUserInteraction, { once: true });
    document.addEventListener('keydown', handleUserInteraction, { once: true });

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };
  }, [isUserInteracted]);

  const playHoverSound = () => {
    if (audio) {
      audio.currentTime = 0;
      audio.play().catch((err) => {
        console.error('Ljudet kunde inte spelas upp:', err);
      });
    }
  };

  return playHoverSound;
};

export default useHoverSound;
