/* Libraries */
import React from 'react';
import clsx from 'clsx';
import Marquee from 'react-marquee-slider';

/* Styles */
import './scrollingtextrecord.scss';

/* Components */

const ScrollingTextRecord = ({ animationText }) => {
  const isMobile = window.innerWidth <= 768;
  const velocity = isMobile ? 20 : 50;

  return (
    <Marquee velocity={velocity} resetAfterTries={200} scatterRandomly={false}>
      {Array(2)
        .fill()
        .map((_, index) => (
          <div key={index} className="scrolling-container">
            <div className={clsx('scrolling-text-container-record')}>
              {animationText}
              <span className="space">-</span>
            </div>
          </div>
        ))}
    </Marquee>
  );
};

export default ScrollingTextRecord;
