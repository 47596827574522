export const fetchSingleHatch = async (url) => {
  try {
    const response = await fetch(url);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Något gick fel vid hämtning av data');
    }

    return data;
  } catch (error) {
    console.error('Fetch error:', error.message);
    return null;
  }
};

export const fetchAllHatches = async (url) => {
  try {
    const response = await fetch(url);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Något gick fel vid hämtning av data');
    }

    return data;
  } catch (error) {
    console.error('Fetch error:', error.message);
    return null;
  }
};
