/* Libraries */
import React, { useState } from 'react';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  FacebookMessengerIcon,
  TwitterShareButton,
  XIcon,
} from 'react-share';
import clsx from 'clsx';

/* Styles */
import './sharebutton.scss';

/* Components */
import Button from '../button/Button';
import CookieDeclaration from '../cookieDeclaration/CookieDeclaration';
import useHoverSound from '../../helpers/useHoverSound';

const ShareModal = ({ isOpen, onClose, url, showShareContent }) => {
  const playHoverSound = useHoverSound();

  if (!isOpen) return null;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    alert('Länken har kopierats!');
  };

  return (
    <div className="modal-overlay">
      <div className="modal-innercontent">
        <button
          className="close-share-btn"
          onClick={onClose}
          onMouseEnter={playHoverSound}
        ></button>
        <div
          className={clsx(
            'modal-content',
            !showShareContent
              ? 'modal-content__cookie'
              : 'modal-content__default',
          )}
        >
          {showShareContent ? (
            <div className="share-container">
              <p>Dela med en vän</p>
              <div className="share-buttons">
                <FacebookShareButton url={url} id="facebook">
                  <FacebookIcon size={52} round className="custom-icon" />
                </FacebookShareButton>
                <FacebookMessengerShareButton url={url} id="facebook-messenger">
                  <FacebookMessengerIcon
                    size={52}
                    round
                    className="custom-icon"
                  />
                </FacebookMessengerShareButton>
                <LinkedinShareButton url={url} id="linkedin">
                  <LinkedinIcon size={52} round className="custom-icon" />
                </LinkedinShareButton>
                <TwitterShareButton url={url} id="twitter">
                  <XIcon size={52} round className="custom-icon" />
                </TwitterShareButton>
              </div>
              <div className="copy-link">
                <input type="text" value={url} readOnly />
                <div className="share-button">
                  <Button
                    id="copy-link"
                    onClick={handleCopyLink}
                    title={'Kopiera'}
                    color={'lightred'}
                    size={'small'}
                  />
                </div>
              </div>
            </div>
          ) : (
            <CookieDeclaration />
          )}
        </div>
      </div>
    </div>
  );
};

const ShareButton = ({ color, title, icon, showShareContent, id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShareClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        title={title}
        color={color}
        icon={icon}
        size={'small'}
        onClick={handleShareClick}
        id={id}
      />
      <ShareModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        url={'https://jul.bazooka.se/'}
        showShareContent={showShareContent}
      />
    </>
  );
};
export default ShareButton;
