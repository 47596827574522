/* Libraries */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

/* Styles */
import './quiz.scss';

/* Components */
import AttemptMessage from '../attemptMessage/AttemptMessage';
import loading from './loading-sound.mp3';
import { playAudio } from '../../helpers/audioPlay';
import { endpoints } from '../../api/urls';
import useHoverSound from '../../helpers/useHoverSound';

const Quiz = ({
  hatchNumber,
  quizData,
  attempts,
  onAnswer,
  setSpotifyLink,
  setRightAnswer,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [disabledOptions, setDisabledOptions] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [animatingOption, setAnimatingOption] = useState(null);
  const [isAnimationComplete, setIsAnimationComplete] = useState(true);
  const [showAttemptMessage, setShowAttemptMessage] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState([]);

  const playHoverSound = useHoverSound();

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('quizData'));
    if (savedData) {
      setSelectedOption(savedData.selectedOption);
      setDisabledOptions(savedData.disabledOptions);
      setFeedbackMessage(savedData.feedbackMessage);
      setErrorCount(savedData.errorCount);
      setCorrectAnswers(savedData.correctAnswers || []);
    }
  }, []);

  const saveToLocalStorage = (data) => {
    localStorage.setItem('quizData', JSON.stringify(data));
  };

  const handleOptionChange = (event) => {
    if (!isAnimationComplete) return;
    playAudio({ sound: loading });

    const isLastAttempt = attempts === 1;

    const selected = event.target.value;
    setSelectedOption(selected);

    setAnimatingOption(selected);
    setIsAnimationComplete(false);
    setShowAttemptMessage(false);

    const url = endpoints.getHatch(hatchNumber);

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Answer: selected, LastAttempt: isLastAttempt }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTimeout(() => {
          const isCorrect = data.data.correct;
          onAnswer(isCorrect);

          if (data.data.spotify_url) {
            setSpotifyLink(data.data.spotify_url);
          }

          if (data?.data?.correct_answer?.spotify_url) {
            setSpotifyLink(data?.data?.correct_answer?.spotify_url);
          }

          if (!isCorrect) {
            if (attempts > 1) {
              setFeedbackMessage(`${attempts - 1}`);
              setDisabledOptions([...disabledOptions, selected]);
              setTriggerAnimation(false);
              setTimeout(() => {
                setTriggerAnimation(true);
              }, 0);
              setErrorCount(errorCount + 1);
            }

            if (data?.data?.correct_answer?.text) {
              setRightAnswer(data?.data?.correct_answer?.text);
            }
          }

          setIsAnimationComplete(true);
          setShowAttemptMessage(true);

          const updatedCorrectAnswers = isCorrect
            ? [...correctAnswers, selected]
            : correctAnswers;

          saveToLocalStorage({
            selectedOption: selected,
            disabledOptions: [...disabledOptions, selected],
            feedbackMessage: `${attempts - 1}`,
            errorCount: errorCount + 1,
            correctAnswers: updatedCorrectAnswers,
          });

          if (isCorrect) {
            setCorrectAnswers(updatedCorrectAnswers);
          }
        }, 4000);
      });
  };

  return (
    <div className={clsx('quiz')}>
      {showAttemptMessage && feedbackMessage && (
        <AttemptMessage
          feedbackMessage={feedbackMessage}
          triggerAnimation={triggerAnimation}
          setTriggerAnimation={setTriggerAnimation}
          errorCount={errorCount}
        />
      )}
      <h2 className="question-title">{quizData?.question}</h2>
      <form className="form">
        {quizData?.options.map((option) => (
          <div
            key={option.id}
            className={clsx('option-container', {
              progress:
                animatingOption === String(option.id) && !isAnimationComplete,
              'correct-answer': correctAnswers.includes(String(option.id)),
            })}
            tabIndex={0}
          >
            <label
              onMouseEnter={() => {
                if (
                  !disabledOptions.includes(String(option.id)) &&
                  selectedOption !== String(option.id)
                ) {
                  playHoverSound();
                }
              }}
              htmlFor={`option-${option.id}`}
              className={clsx('label', {
                'label-disabled':
                  (disabledOptions.includes(String(option.id)) ||
                    attempts === 0) &&
                  (isAnimationComplete ||
                    animatingOption !== String(option.id)),
              })}
            >
              <input
                type="radio"
                id={`option-${option.id}`}
                name={`question-${quizData?.hatch_number}`}
                value={String(option.id)}
                className="radio-button"
                checked={selectedOption === String(option.id)}
                onChange={handleOptionChange}
                disabled={
                  !isAnimationComplete ||
                  ((disabledOptions.includes(String(option.id)) ||
                    attempts === 0) &&
                    (isAnimationComplete ||
                      animatingOption !== String(option.id)))
                }
              />
              <span className="option-text">{option.text}</span>
            </label>
          </div>
        ))}
      </form>
    </div>
  );
};

export default Quiz;
