/* Libraries */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { gsap } from 'gsap';

/* Styles */
import './threeblocks.scss';

/* Components */

const ThreeBlocks = ({
  isFailure,
  isSuccess,
  activeDate,
  today,
  isPopupActive,
}) => {
  const dateRef = useRef(null);
  const [previousDate, setPreviousDate] = useState(today + 5);

  useEffect(() => {
    const element = dateRef.current;
    const startDate = today;
    const endDate = activeDate || today;

    if (startDate !== endDate) {
      const timeline = gsap.timeline();

      timeline.to(element, {
        duration: 2,
        delay: 0.2,
        textContent: endDate,
        roundProps: 'textContent',
        ease: 'power3.out',
        onUpdate: function () {
          element.textContent = Math.round(element.textContent);
        },
      });
    } else {
      element.textContent = startDate;
    }
  }, [activeDate, today]);

  useEffect(() => {
    if (isPopupActive) return;

    const element = dateRef.current;
    const startDate = previousDate;
    const endDate = activeDate || today;

    const timeline = gsap.timeline();

    timeline.fromTo(
      element,
      { textContent: startDate },
      {
        textContent: endDate,
        duration: 2,
        roundProps: 'textContent',
        ease: 'power3.out',
        onUpdate: function () {
          element.textContent = Math.round(element.textContent);
        },
      },
    );
    setPreviousDate(endDate);
  }, [isPopupActive, today, activeDate]);

  let videoUrl = `${process.env.PUBLIC_URL}/gingerbread-video.mp4`; // Default video

  if (isSuccess) {
    videoUrl = `${process.env.PUBLIC_URL}/gingerbread-video-yes.mp4`; // Video for success
  } else if (isFailure) {
    videoUrl = `${process.env.PUBLIC_URL}/gingerbread-video-no.mp4`; // Video for failure
  }

  return (
    <div className="three-blocks">
      <div className="three-blocks-calendar">
        <div className="three-blocks-calendar-dots">
          <img
            src={`${process.env.PUBLIC_URL}/calendar-dots-desktop.svg`}
            alt="calendar dots"
          />
        </div>
        <div className="three-blocks-calendar-text">
          <p className="three-blocks-calendar-text-hatch">Lucka</p>
          <p ref={dateRef} className="three-blocks-calendar-text-date">
            {today}
          </p>
        </div>
      </div>
      <div className="three-blocks-image">
        <video className="video" key={videoUrl} autoPlay muted loop playsInline>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="three-blocks-logo">
        <img srcSet={`${process.env.PUBLIC_URL}/icon-desktop.svg`} />
      </div>
    </div>
  );
};

export default ThreeBlocks;
