/* Libraries */
import React from 'react';
import clsx from 'clsx';

/* Styles */
import './logo.scss';

/* Components */
const Logo = ({ logo, quizLogo, aiQuiz }) => {
  const logoPath = `/${logo}`;
  const quizLogoPath = `/${quizLogo}`;

  return (
    <div className={clsx('logo')}>
      <img
        alt="Bazooka text logo"
        src={logoPath}
        className={clsx('logo-img')}
      />
      <img
        alt="Bazooka quiz logo"
        src={quizLogoPath}
        className={clsx('quiz-logo-img')}
      />
    </div>
  );
};
export default Logo;
