/* Libraries */
import React from 'react';
import clsx from 'clsx';
import { useLottie } from 'lottie-react';

import yes from './yes.json';
import no from './no.json';

/* Styles */
import './resultmessagelayout.scss';

/* Components */
import ScrollingText from '../scrollingText/ScrollingText';
import ShareButton from '../shareButton/ShareButton';
import Button from '../button/Button';

const ResultMessageLayout = ({
  animationText = '',
  title = '',
  text = '',
  btnColor = '',
  isFailure = false,
  spotifyLink = '',
  rightAnswer,
}) => {
  const options = {
    animationData: isFailure ? no : yes,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <div className={clsx('result-message-layout')}>
      <div className="animation">{View}</div>
      <ScrollingText isFailure={isFailure} animationText={animationText} />
      <div className={clsx('text-content')}>
        <div>
          <h2 className={clsx('text-title')}>{title}</h2>
          <p>{text}</p>
          {isFailure && (
            <p className={clsx('right-answer')}>Rätt svar: {rightAnswer}</p>
          )}
        </div>
        <div className="btnContainer">
          <ShareButton
            title="Dela"
            color={btnColor}
            icon={'share'}
            size={'small'}
            showShareContent={true}
          />
          <Button
            title={'Lyssna på Spotify'}
            color={btnColor}
            icon={'share'}
            size={'small'}
            href={spotifyLink}
          />
        </div>
      </div>
    </div>
  );
};
export default ResultMessageLayout;
