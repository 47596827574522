/* Libraries */
import React from 'react';
import clsx from 'clsx';
import Marquee from 'react-marquee-slider';

/* Styles */
import './scrollingtext.scss';

/* Components */

const ScrollingText = ({ isFailure, animationText }) => {
  const isMobile = window.innerWidth <= 768;
  const velocity = isMobile ? 30 : 230;

  return (
    <Marquee velocity={velocity}>
      {[
        <div key="scrolling-container" className="scrolling-container">
          <div
            className={clsx(
              'scrolling-text-container',
              isFailure
                ? 'scrolling-text__redColor'
                : 'scrolling-text__greenColor',
            )}
          >
            <span className="scrolling-text"> {animationText}</span>
            <span className="space">-</span>
          </div>
        </div>,
      ]}
    </Marquee>
  );
};
export default ScrollingText;
