/* Libraries */
import React from 'react';
import clsx from 'clsx';

/* Styles */
import './datecontainer.scss';

/* Components */
import Dates from '../dates/Dates';
import Button from '../button/Button';
import ShareButton from '../shareButton/ShareButton';

const DateContainer = ({
  className,
  activeDate,
  setActiveDate,
  togglePopup,
  allHatches,
  setLatestHatchNumber,
}) => {
  return (
    <div className={clsx('dates-container', className)}>
      <Dates
        activeDate={activeDate}
        setActiveDate={setActiveDate}
        allHatches={allHatches}
        setLatestHatchNumber={setLatestHatchNumber}
      />
      <div className="btnContainer">
        <ShareButton
          title="Dela"
          color="darkred"
          icon={'share'}
          showShareContent={true}
          id="share-button"
        />
        <Button
          title="Hjälp"
          color="darkred"
          icon="help"
          size="small"
          onClick={togglePopup}
        />
        <ShareButton
          title="Cookie"
          color="darkred"
          icon="cookie"
          showShareContent={false}
        />
      </div>
    </div>
  );
};
export default DateContainer;
