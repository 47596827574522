/* Libraries */
import React, { useEffect } from 'react';
import clsx from 'clsx';

/* Styles */
import './startpopup.scss';

/* Functions */
import useHoverSound from '../../helpers/useHoverSound';

/* Components */
import Logo from '../logo/Logo';
import Button from '../button/Button';

const StartPopup = ({ isActive, setIsActive }) => {
  const playHoverSound = useHoverSound();

  const handleClick = () => {
    localStorage.setItem('seenPopUp', true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.body.classList.remove('popup-active');
    setIsActive(false);
  };

  useEffect(() => {
    let returningUser = localStorage.getItem('seenPopUp');
    setIsActive(!returningUser);
    if (!returningUser) {
      document.body.classList.add('popup-active');
    }
  }, []);

  return (
    <div className={clsx('start-popup', { isShown: !isActive })}>
      <div className={clsx('start-popup-container')}>
        <button
          className={clsx('close')}
          onClick={handleClick}
          onMouseEnter={playHoverSound}
        />
        <div className={clsx('start-popup-content')}>
          <div className={clsx('logo-container')}>
            <Logo logo={'logo-white.svg'} quizLogo={'quiz-logo-red.svg'} />
          </div>
          <div className={clsx('start-quiz')}>
            <img src="/gingerbread-start.svg" alt="gingerbread" />
            <Button
              title={'Starta quizet här!'}
              size={'large'}
              color={'lightred'}
              onClick={handleClick}
            ></Button>
            <span>
              Läs om quizet på{' '}
              <a
                href="https://www.bazooka.se/musik-ai-och-julmagi/"
                target="_blank"
                rel="noreferrer"
              >
                bazooka.se
              </a>
            </span>
          </div>
          <div className={clsx('how-to')}>
            <h2>Så här fungerar quizkalendern</h2>
            <div className={clsx('how-to-content')}>
              <ol>
                <li>Höj volymen - klicka på play</li>
                <li>Svara på frågan om dagens AI-genererade jullåt</li>
                <li>Du har tre försök på dig</li>
              </ol>
              <div className={clsx('good-luck')}>
                <p>
                  Ny lucka varje dag fram till julafton. Lycka till och Merry
                  Quizmas!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StartPopup;
