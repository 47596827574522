/* Libraries */
import React, { useEffect, useRef } from 'react';

/* Styles */
import './successmessage.scss';

/* Components */
import ResultMessageLayout from '../resultMessageLayout/ResultMessageLayout';
import successSound from './win-sound.mp3';

const SuccessMessage = ({ title, text, spotifyLink }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    // Skapa en ny ljudinstans och spela upp ljudet
    audioRef.current = new Audio(successSound);
    audioRef.current.play().catch((err) => {
      console.error('Ljudet kunde inte spelas upp:', err);
    });

    // Stoppa och rensa ljudet när komponenten unmountas
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Återställ ljudet
        audioRef.current = null;
      }
    };
  }, []);

  return (
    <ResultMessageLayout
      animationText="Yeeeeea!!"
      title={title}
      text={text}
      btnColor="lightred"
      isFailure={false}
      spotifyLink={spotifyLink}
    />
  );
};

export default SuccessMessage;
