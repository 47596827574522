/* Libraries */
import React from 'react';
import clsx from 'clsx';

/* Styles */
import './calendar.scss';

/* Components */
import Logo from '../logo/Logo';
import DateContainer from '../dateContainer/DateContainer';

const Calendar = ({
  allHatches,
  setLatestHatchNumber,
  activeDate,
  setActiveDate,
  today,
  togglePopup,
}) => {
  return (
    <div className={clsx('calendar')}>
      <Logo logo={'logo.svg'} quizLogo={'quiz-logo-green.svg'} />
      <DateContainer
        className={'dates-container-desktop'}
        activeDate={activeDate}
        setActiveDate={setActiveDate}
        today={today}
        togglePopup={togglePopup}
        allHatches={allHatches}
        setLatestHatchNumber={setLatestHatchNumber}
      />
    </div>
  );
};

export default Calendar;
