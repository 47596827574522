import React, { useId } from 'react';

/* Styles */
import './dates.scss';

/* Components */
import useHoverSound from '../../helpers/useHoverSound';

const Dates = ({
  activeDate,
  setActiveDate,
  allHatches,
  setLatestHatchNumber,
}) => {
  const id = useId();
  const dates = Array.from({ length: 24 }, (_, i) => i + 1);

  const playHoverSound = useHoverSound();

  const lastHatch = allHatches?.length
    ? allHatches[allHatches.length - 1]
    : null;

  const handleDateClick = (date) => {
    setActiveDate(date);

    const selectedHatch = allHatches?.find(
      (hatch) => hatch.hatch_number === date,
    );
    if (selectedHatch) {
      setLatestHatchNumber(selectedHatch.hatch_number);
    }
  };

  return (
    <div className="dates">
      {dates.map((date, index) => {
        let className = '';
        if (date < lastHatch?.hatch_number) {
          className = 'past';
        } else if (date === lastHatch?.hatch_number) {
          className = 'current';
        } else {
          className = 'future';
        }

        if (date === activeDate) {
          className += ' active';
        }

        return (
          <button
            key={`${id}-${index}`}
            className={`date ${className}`}
            onClick={() => handleDateClick(date)}
            onMouseEnter={playHoverSound}
            disabled={date > lastHatch?.hatch_number}
          >
            <span>{date}</span>
          </button>
        );
      })}
    </div>
  );
};

export default Dates;
