import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

/* Styles */
import './musicgame.scss';

/* Components */
import MusicPlayer from '../musicPlayer/MusicPlayer';
import SuccessMessage from '../successMessage/SuccessMessage';
import FailureMessage from '../failureMessage/FailureMessage';

/* Helpers */
import { getRandomMessage } from '../../helpers/getRandomMessage';

const MusicGame = ({
  quizData,
  isFailure,
  setIsFailure,
  isSuccess,
  setIsSuccess,
  hatchNumber,
  spotifyLink,
  setSpotifyLink,
  setRightAnswer,
  rightAnswer,
}) => {
  const [attempts, setAttempts] = useState(3);
  const [randomSuccessMessage, setRandomSuccessMessage] = useState(
    getRandomMessage('success'),
  );
  const [randomFailureMessage, setRandomFailureMessage] = useState(
    getRandomMessage('failure'),
  );
  const musicPlayerRef = useRef(null);

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem('musicGameState')) || {};
    const currentHatchState = savedState[hatchNumber] || {
      isFailure: false,
      isSuccess: false,
      attempts: 3,
      rightAnswer: '',
      spotifyLink: '',
    };

    setIsFailure(currentHatchState.isFailure);
    setIsSuccess(currentHatchState.isSuccess);
    setAttempts(currentHatchState.attempts);
    setRightAnswer(currentHatchState.rightAnswer);
    setSpotifyLink(currentHatchState.spotifyLink);
    setRandomSuccessMessage(getRandomMessage('success'));
    setRandomFailureMessage(getRandomMessage('failure'));

    if (musicPlayerRef.current) {
      musicPlayerRef.current.resetPlayer();
    }
  }, [hatchNumber, setIsFailure, setIsSuccess, setRightAnswer, setSpotifyLink]);

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem('musicGameState')) || {};
    savedState[hatchNumber] = {
      isFailure,
      isSuccess,
      attempts,
      rightAnswer,
      spotifyLink,
    };
    localStorage.setItem('musicGameState', JSON.stringify(savedState));
  }, [isFailure, isSuccess, attempts, rightAnswer, hatchNumber, spotifyLink]);

  const handleAnswer = (isCorrect) => {
    if (isCorrect) {
      setIsSuccess(true);
    } else {
      setAttempts((prev) => prev - 1);
      if (attempts - 1 === 0) {
        setIsFailure(true);
      }
    }
  };

  if (isSuccess) {
    if (hatchNumber === 24) {
      return (
        <SuccessMessage
          title={getRandomMessage('success', true).title}
          text={getRandomMessage('success', true).text}
          spotifyLink={spotifyLink}
        />
      );
    } else {
      return (
        <SuccessMessage
          title={randomSuccessMessage.title}
          text={randomSuccessMessage.text}
          spotifyLink={spotifyLink}
        />
      );
    }
  }

  if (isFailure) {
    if (hatchNumber === 24) {
      return (
        <FailureMessage
          title={getRandomMessage('failure', true).title}
          text={getRandomMessage('failure', true).text}
          spotifyLink={spotifyLink}
          rightAnswer={rightAnswer}
        />
      );
    } else {
      return (
        <FailureMessage
          title={randomFailureMessage.title}
          text={randomFailureMessage.text}
          spotifyLink={spotifyLink}
          rightAnswer={rightAnswer}
        />
      );
    }
  }

  return (
    <div className={clsx('music-game')}>
      <MusicPlayer
        ref={musicPlayerRef}
        hatchNumber={hatchNumber}
        quizData={quizData}
        attempts={attempts}
        onAnswer={handleAnswer}
        spotifyLink={spotifyLink}
        setSpotifyLink={setSpotifyLink}
        setRightAnswer={setRightAnswer}
      />
    </div>
  );
};

export default MusicGame;
