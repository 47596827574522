import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { gsap } from 'gsap';
import clsx from 'clsx';

/* Styles */
import './musicplayer.scss';

/* Components */
import Quiz from '../quiz/Quiz';
import playSound from './play-sound.mp3';
import PlayFirst from './play-first.mp3';
import PauseSound from './paus.mp3';
import { playAudio } from '../../helpers/audioPlay';
import ScrollingTextRecord from '../ScrollingTextRecord/ScrollingTextRecord';
import useHoverSound from '../../helpers/useHoverSound';

const MusicPlayer = forwardRef(
  (
    {
      hatchNumber,
      quizData,
      attempts = 3,
      onAnswer = () => {},
      setSpotifyLink,
      setRightAnswer,
    },
    ref,
  ) => {
    const recordRef = useRef(null);
    const audioRef = useRef(null);
    const secondRecordRef = useRef(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [hasFlipped, setHasFlipped] = useState(false);
    const [hasFlippedFully, setHasFlippedFully] = useState(false);
    const [countClick, setCountClick] = useState(0);

    const isMobile = window.innerWidth <= 768;

    const audioContextRef = useRef(null);
    const playFirstBufferRef = useRef(null);
    const playSecondBufferRef = useRef(null);
    const audioSourceRef = useRef(null);

    const playHoverSound = useHoverSound();

    useEffect(() => {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();

      fetch(PlayFirst)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) =>
          audioContextRef.current.decodeAudioData(arrayBuffer),
        )
        .then((audioBuffer) => {
          playFirstBufferRef.current = audioBuffer;
        });
      if (quizData?.audio) {
        fetch(quizData?.audio)
          .then((response) => response.arrayBuffer())
          .then((arrayBuffer) =>
            audioContextRef.current.decodeAudioData(arrayBuffer),
          )
          .then((audioBuffer) => {
            playSecondBufferRef.current = audioBuffer;
          });
      }

      firstSpin();

      const audioElement = audioRef.current;
      audioElement.addEventListener('ended', handleAudioEnd);
      return () => {
        audioElement.removeEventListener('ended', handleAudioEnd);
        audioContextRef.current.close();
      };
    }, [quizData?.audio]);

    const firstSpin = () => {
      if (isMobile) {
        gsap.to([recordRef.current, secondRecordRef.current], {
          rotation: '+=649',
          duration: 6,
          ease: 'power1.out',
        });
      } else {
        gsap.to([recordRef.current, secondRecordRef.current], {
          rotation: '+=720',
          duration: 6,
          ease: 'power1.out',
        });
      }
    };

    const handleAudioEnd = () => {
      setIsPlaying(false);
      playSoundWithWebAudio(playSecondBufferRef.current);
    };

    const playSoundWithWebAudio = (audioBuffer, onEndedCallback = null) => {
      const audioContext = audioContextRef.current;

      if (audioContext && audioBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.destination);
        source.start(0);

        source.onended = onEndedCallback;
        audioSourceRef.current = source;

        return source;
      }
    };

    const spin = () => {
      gsap.to(secondRecordRef.current, {
        rotation: '+=180',
        duration: 1,
        ease: 'linear',
        onComplete: () => {
          spin();
        },
      });
    };

    const spinSlowStart = () => {
      gsap.to(secondRecordRef.current, {
        rotation: '+=180',
        duration: 2,
        ease: 'power1.in',
        onComplete: () => {
          spin();
        },
      });
    };

    const spinSlowDown = () => {
      if (hasFlippedFully) {
        gsap.killTweensOf(secondRecordRef.current);
      }
      gsap.to(secondRecordRef.current, {
        rotation: '+=180',
        duration: 2,
        ease: 'power1.out',
      });
    };

    const flipRecord = () => {
      gsap.killTweensOf(recordRef.current);
      gsap.killTweensOf(secondRecordRef.current);

      // Flip the record
      const tl = gsap.timeline();

      tl.to([recordRef.current, secondRecordRef.current], {
        rotationY: 90,
        duration: 0.5,
        ease: 'power2.inOut',
        onStart: () => {
          playSoundWithWebAudio(playFirstBufferRef.current);
          setTimeout(() => {
            playSoundWithWebAudio(playSecondBufferRef.current, handleAudioEnd);
          }, [2200]);
        },
        onComplete: () => {
          setHasFlipped(true);
          gsap.set(recordRef.current, { visibility: 'hidden' });
          gsap.set(secondRecordRef.current, { visibility: 'visible' });
        },
      }).to(secondRecordRef.current, {
        rotationY: 180,
        duration: 0.5,
        ease: 'power2.inOut',
        onComplete: () => {
          setHasFlippedFully(true);
          spinSlowStart();
        },
      });
    };

    useEffect(() => {
      document
        .querySelector('.music-player__button')
        .addEventListener('click', () => {
          setCountClick(countClick + 1);
        });

      if (isPlaying) {
        if (countClick === 1 && !hasFlipped) {
          flipRecord();
        } else if (countClick > 1 && hasFlipped) {
          spinSlowStart();
          audioContextRef.current.resume();
          playAudio({ sound: playSound });
        }
      } else {
        if (hasFlippedFully) {
          audioContextRef.current.suspend();
          spinSlowDown();
          playAudio({ sound: PauseSound });
        }
      }
    }, [isPlaying, hasFlippedFully]);

    const togglePlay = () => {
      setIsPlaying(!isPlaying);
    };

    // Reset when date is changed
    useImperativeHandle(ref, () => ({
      resetPlayer: () => {
        setIsPlaying(false);
        setHasFlipped(false);
        setHasFlippedFully(false);
        setCountClick(0);
        gsap.set(recordRef.current, { visibility: 'visible', rotationY: 0 });
        gsap.set(secondRecordRef.current, {
          visibility: 'hidden',
          rotationY: 0,
        });
        gsap.to([recordRef.current, secondRecordRef.current], {
          rotation: '+=720',
          duration: 6,
          ease: 'power1.out',
        });
      },
    }));

    return (
      <div className="music-player">
        <div className="music-player-record-label">
          <div className="scrolling-text">
            <ScrollingTextRecord
              animationText={'Bazookas ganska mysiga julquiz'}
            />
          </div>
          <img
            ref={recordRef}
            src={quizData?.vinyl_front}
            alt="Vinyl record"
            className={clsx({ hidden: hasFlipped })}
          />
          <img
            ref={secondRecordRef}
            src={quizData?.vinyl_back}
            alt="Vinyl record gingerbread"
            className={clsx({ hidden: !hasFlipped })}
          />
        </div>
        <div className="quiz-container">
          <button
            id="music-player-button"
            className={clsx(
              'music-player__button',
              isPlaying ? 'music-player__pause' : 'music-player__play',
            )}
            aria-label={isPlaying ? 'Pause' : 'Play'}
            onClick={togglePlay}
            onMouseEnter={playHoverSound}
          ></button>
          <audio ref={audioRef} src={quizData?.audio} />
          <Quiz
            hatchNumber={hatchNumber}
            quizData={quizData}
            attempts={attempts}
            onAnswer={onAnswer}
            setSpotifyLink={setSpotifyLink}
            setRightAnswer={setRightAnswer}
          />
        </div>
      </div>
    );
  },
);

export default MusicPlayer;
