const successMessages = [
  {
    title: 'Du knäckte det!',
    text: 'Inte så seg i kolan direkt! Vi ses imorgon!',
  },
  { title: 'Deck the halls!', text: 'Det var rätt svar! På´t igen imorgon!' },
  { title: 'Joy to the world!', text: 'Du hade rätt! Ny chans imorgon!' },
  { title: 'Vilken julsaga!', text: 'Bra gissat! Ny fråga imorgon!' },
  { title: 'Du är en julstjärna!', text: 'Rätt svar! Vi ses imorgon!' },
  { title: 'Heja tomtegubbar!', text: 'Rätt gissat! Ny fråga imorgon!' },
];

const failureMessages = [
  {
    title: 'Yippee-ki-yay, motherf*.',
    text: 'Det här var visst inte din grej. Ny chans imorgon!',
  },
  {
    title: 'Det här gick ju åt hel…iga natt!',
    text: 'Julstämningen dog totalt. Ny chans imorgon!',
  },
  {
    title: 'Du är inte en av de tre vise männen va?',
    text: 'Det märks. Ny chans att svara rätt imorgon!',
  },
  {
    title: 'Inget lysande svar.',
    text: 'Din julbelysning verkar ha slocknat helt. Ny chans imorgon!',
  },
  {
    title: 'Ditt misslyckande är som en chokladask…',
    text: '...där bara körsbärslikören är kvar. Nytt försök imorgon!',
  },
  {
    title: 'Segt att du svarade fel.',
    text: 'Det här hade en skumtomte klarat bättre. Ny chans imorgon!',
  },
  {
    title: 'Knäckande fel.',
    text: 'Ta en pepparkaka och försök igen imorgon!',
  },
];

const finalSuccessMessage = {
  title: 'Rätt gissat på självaste julafton!',
  text: 'Det var sista luckan! Merry Quizmas!',
};
const finalFailureMessage = {
  title: 'Fel svar på självaste julafton!',
  text: 'Hoppas julen blir mer lyckad än ditt svar! Merry Quizmas!',
};

function getRandomIndex(arrayLength) {
  return Math.floor(Math.random() * arrayLength);
}

export function getRandomMessage(type, isFinal = false) {
  if (isFinal) {
    return type === 'success' ? finalSuccessMessage : finalFailureMessage;
  }

  let messages;
  switch (type) {
    case 'success':
      messages = successMessages;
      break;
    case 'failure':
      messages = failureMessages;
      break;
    default:
      return null;
  }
  const randomIndex = getRandomIndex(messages.length);
  return messages[randomIndex];
}
